import { apiSlice } from "./apiSlice"

const extendedApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		postSignup: build.mutation({
			query: data => ({
				url: '/register',
				method: 'POST',
				body: {
					page_name: data.pageName,
					email: data.email,
					password: data.password,
					password_conf: data.passwordConf,
					timezone: data.timezone,
					affiliate_code: data.affiliateCode,
					allow_name_registration: data.allowNameRegistration ?? null,
					invite_code: data.inviteCode ?? null,
					cycle: data.cycle,
					tier: data.tier,
					quantity: data.quantity,
					fbp: data.fbp,
					fbc: data.fbc,
					gaCookie: data.gaCookie
				}
			})
		}),
		postLogin: build.mutation({
			query: data => ({
				url: '/login',
				method: 'POST',
				body: {
					email: data.email,
					password: data.password
				}
			})
		}),
		postForgotPassword: build.mutation({
			query: data => ({
				url: '/password/forgot',
				method: 'POST',
				body: {
					email: data.email
				}
			})
		}),
		postResetPassword: build.mutation({
			query: data => ({
				url: '/password/reset',
				method: 'POST',
				body: {
					email: data.email,
					password: data.password,
					password_confirmation: data.passwordConfirmation,
					token: data.token
				}
			})
		}),
		postLogout: build.mutation({
			query: () => ({
				url: '/logout',
				method: 'POST',
				body: {}
			})
		}),
		postTokenVerifyPassword: build.mutation({
			query: data => ({
				url: '/token/password/verify/',
				method: 'POST',
				body: {
					token: data.token,
					email: data.email
				}
			})
		}),
		postResendConfirmationEmail: build.mutation({
			query: data => ({
				url: '/confirm/resend',
				method: 'POST',
			})
		}),
		postUserConfirm: build.mutation({
			query: data => ({
				url: '/confirm',
				method: 'POST',
				body: {
					email: data.email,
					code: data.code,
				}
			})
		}),
		postResendConfirmEmailChange: build.mutation({
			query: data => ({
				url: '/confirm/email/change/resend',
				method: 'POST',
			})
		}),
		postUserConfirmEmailChange: build.mutation({
			query: data => ({
				url: '/confirm/email/change',
				method: 'POST',
				body: {
					email: data.email,
					code: data.code,
				}
			})
		}),
		postAdminResendInvite: build.mutation({
			query: data => ({
				url: `/page/admin/resend-invite`,
				method: 'POST',
				body: {
					invite_code: data.invite_code
				}
			})
		}),
		postUserPageAdminAccept: build.mutation({
			query: data => ({
				url: '/confirm/admin/accept',
				method: 'POST',
				body: {
					invite_code: data.invite_code
				}
			})
		}),
		getMe: build.query({
			query: () => ({url: '/me', method: 'get' })
			
		}),
		getMeCheck: build.query({
			query: () => ({url: '/me/check', method: 'get' })
			
		}),
		getSocialLoginLinks: build.query({
			query: () => ({url: '/login/links', method: 'get' }),
		}),
		getInviteCode: build.query({
			query: (data) => ({ url: `/code/admin-invite/${data.inviteCode}`, method: 'get' })
			
		}),
	}),
	overrideExisting: false,
})

export const {
	usePostLoginMutation,
	usePostSignupMutation,
	usePostForgotPasswordMutation,
	usePostResetPasswordMutation,
	useGetMeQuery,
	useGetMeCheckQuery,
	useGetSocialLoginLinksQuery,
	useGetInviteCodeQuery,
	usePostTokenVerifyPasswordMutation,
	usePostResendConfirmationEmailMutation,
	usePostUserConfirmMutation,
	usePostResendConfirmEmailChangeMutation,
	usePostUserConfirmChangeMutation,
	usePostUserConfirmEmailChangeMutation,
	usePostAdminResendInviteMutation,
	usePostUserPageAdminAcceptMutation,
	usePostLogoutMutation,
	
	util: { getRunningQueriesThunk }
} = extendedApi

export const {
	getMe,
	getSocialLoginLinks
} = extendedApi.endpoints;
