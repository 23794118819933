import React, {useEffect, useState} from 'react';
import Layout from "../components/Layout";
import axios from "axios";
import {getRunningQueriesThunk, getSocialLoginLinks, useGetMeCheckQuery, useGetSocialLoginLinksQuery, usePostLoginMutation} from "../rtk-api/auth";
import {deleteCookie, everythingAfter, getCookie} from "../helpers/utils";
import {useGeneralStore} from "../stores/general";
import SocialLogins from "./inc/socialLogins";
import Link from "next/link";
import { useRouter } from 'next/router'
import {wrapper} from "../store";
import { NextSeo } from 'next-seo';

export const getServerSideProps = wrapper.getServerSideProps(store => async ({req, params}) => {
	store.dispatch(getSocialLoginLinks.initiate());
	await Promise.all(store.dispatch(getRunningQueriesThunk()));
	
	return {
		props: {
			pricingInfo: everythingAfter('?', req.url)
		},
	};
});
function Login({pricingInfo}) {
	const router = useRouter()
	const setShowGlobalLoading = useGeneralStore((state) => state.setShowGlobalLoading)
	const [email, setEmail] = useState(false);
	const [password, setPassword] = useState(false);
	const [postLogin, { isLoading, error }] = usePostLoginMutation()
	const {data:getSocialLinks} = useGetSocialLoginLinksQuery()
	const {data:meInfo} = useGetMeCheckQuery()
	const [errorList, setErrorList] = useState({});
	const [submitText, setSubmitText] = useState('Login');
	const [disableSubmit, setDisableSubmit] = useState(false);
	
	
	useEffect(() => {
		if (error) {
			if (error.data.errors === undefined) {
				setErrorList(error.data)
			} else {
				setErrorList(error.data.errors)
			}
			setShowGlobalLoading(false)
		}
	}, [error]);
	
	const clearErrors = () => {
		setErrorList(false);
	}
	
	const handleLogin =  async (e) => {
		e.preventDefault();
		clearErrors()
		setSubmitText('Please Wait...')
		setDisableSubmit(true)
		deleteCookie('XSRF-TOKEN')
		
		axios.defaults.withCredentials = true;
		axios.get(process.env.NEXT_PUBLIC_API_URL + '/sanctum/csrf-cookie').then(() => {
			postLogin({
				email,
				password,
				cookie:getCookie('XSRF-TOKEN'),
				gaCookie: getCookie('_ga')
			})
				.unwrap()
				.then((payload) => {
					setShowGlobalLoading(true)
					if (router.query.next !== undefined) {
						window.location.replace(router.query.next);
					} else {
						window.location.replace(process.env.NEXT_PUBLIC_APP_URL);
					}
				})
				.catch((error) => {
					setDisableSubmit(false)
					setSubmitText('Login')
				})
		})
	}
	
	if (meInfo) {
		setShowGlobalLoading(true)
		if (meInfo.is_logged_in) {
			window.location.replace(process.env.NEXT_PUBLIC_APP_URL);
		} else {
			setShowGlobalLoading(false)
			return (
				<Layout title="Login">
					<NextSeo
						title="PageName - Login"
						description="Login to PageName"
						openGraph={{
							type: 'website',
							url: process.env.NEXT_PUBLIC_AUTH_URL,
							title: 'PageName - Login',
							description: 'Login to PageName',
							images: [
								{
									url: process.env.NEXT_PUBLIC_AUTH_URL + '/images/og-image.png',
									width: 1200,
									height: 641,
									alt: 'Login to PageName',
									type: 'image/png',
								}
							],
							siteName: 'PageName',
						}}
						twitter={{
							site: '@pagenamer',
							cardType: 'summary_large_image',
						}}
					/>
					<section className="main-content signin section illustration-section-01 has-top-divider px-10">
						<div className="container mx-auto">
							<div className="signin-inner section-inner ">
								<div className="section-header text-center mb-10">
									<h1 className="heading text-5xl font-bold ">Login</h1>
								</div>
								<div className="tiles-wrap">
									<div className="col-10">
										<div className="tiles-item-inner">
											
											<SocialLogins
												socialLinks={getSocialLinks.data}
												queryString={pricingInfo}
												text='Log in'
											/>
											
											<div className="separator my-6">OR</div>
											
											{Object.keys(errorList).length !== 0 &&
												<div className="alert alert-danger js_alert" data-cy="alert-danger" role="alert">
													<div className="m-0 p-0 list-group">
														<div>The email or password you entered is invalid.</div>
													</div>
												</div>
											}
											
											<form onSubmit={e => handleLogin(e)}>
												<fieldset className="border-b pb-10 mb-10">
													<div className="mb-3">
														<label className="form-label sr-only" htmlFor="email">Email Address or PageName</label>
														<input
															id="email"
															data-cy="name"
															name="email"
															className="form-input"
															type="text"
															placeholder="Email Address or PageName"
															onChange={e => {
																clearErrors()
																setEmail(e.target.value)
															}}
															required/>
													</div>
													<div className="mb-3">
														<label className="form-label sr-only" htmlFor="password">Password</label>
														<input
															id="password"
															data-cy="password"
															name="password"
															className="form-input"
															type="password"
															placeholder="Password"
															onChange={e => {
																clearErrors()
																setPassword(e.target.value)
															}}
															required
														/>
													</div>
													<div className="mb-4">
														<label className="form-checkbox cursor-pointer flex items-center">
															<input
																type="checkbox"
																className="cursor-pointer mr-2"
																id="remember"
																name="rememberme"
															/>
															<span>Remember me</span>
														</label>
													</div>
													<div className="mb-6">
														<button
															type="submit"
															disabled={disableSubmit}
															className="btn-primary w-full py-3 uppercase"
														>
															{submitText}
														</button>
													</div>
													<Link href="/password/forgot" className="">
														Forgot password?
													</Link>
												</fieldset>
											</form>
											<div className="mb-10">
												<div className="text-center">
													Don&apos;t have an account? <Link className="func-link" href="/signup">Sign up</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Layout>
			);
		}
	}
}

export default Login;
