import React from 'react';
import Link from "next/link";
import {AppleLoginButton, FacebookLoginButton, GoogleLoginButton, XLoginButton} from "react-social-login-buttons";

function SocialLogins({ socialLinks, queryString, text }) {
	
	function go(url) {
		window.location.href = url
	}
	
	return (
		<>
			{/*<a href={process.env.NEXT_PUBLIC_DEBUG_NEW_USER_URL}>DEBUG Create a new user</a><br/>*/}
			{/*<a href={process.env.NEXT_PUBLIC_DEBUG_NEW_USER_STRIPE_URL}>DEBUG Create a new user with stripe</a><br/>*/}
			
			<AppleLoginButton
				className="socialButtons"
				onClick={() => go(socialLinks?.apple + queryString)}
				style={{
					fontSize: "1em",
					background: "white",
					border: "1px solid #E0E2D9",
					boxShadow: "none",
					color: "black",
					fontWeight: "bold",
					borderRadius: "64px"
				}}
				activeStyle={{
					backgroundColor: "#E0E2D9"
				}}
				align="center"
			>
				{text} With Apple
			</AppleLoginButton>
			
			<GoogleLoginButton
				className="socialButtons"
				onClick={() => go(socialLinks?.google + queryString)}
				style={{
					fontSize: "1em",
					background: "white",
					border: "1px solid #E0E2D9",
					boxShadow: "none",
					color: "black",
					fontWeight: "bold",
					borderRadius: "64px"
				}}
				activeStyle={{
					backgroundColor: "#E0E2D9"
				}}
				align="center"
			>
				{text} with Google
			</GoogleLoginButton>
			
			<FacebookLoginButton
				className="socialButtons"
				onClick={() => go(socialLinks?.facebook + queryString)}
				style={{
					fontSize: "1em",
					background: "white",
					border: "1px solid #E0E2D9",
					boxShadow: "none",
					color: "black",
					fontWeight: "bold",
					borderRadius: "64px"
				}}
				activeStyle={{
					backgroundColor: "#E0E2D9"
				}}
				align="center"
				iconColor="#1877F2"
			>
				{text} with Facebook
			</FacebookLoginButton>
			
			<XLoginButton
				className="socialButtons"
				onClick={() => go(socialLinks?.twitter + queryString)}
				style={{
					fontSize: "1em",
					background: "white",
					border: "1px solid #E0E2D9",
					boxShadow: "none",
					color: "black",
					fontWeight: "bold",
					borderRadius: "64px"
				}}
				activeStyle={{
					backgroundColor: "#E0E2D9"
				}}
				align="center"
				iconColor="#000000"
			>
				{text} with X
			</XLoginButton>
		</>
	);
}

export default SocialLogins;
