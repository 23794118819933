export const isLocal = () => {
	return process.env.NEXT_PUBLIC_RELEASE_STAGE === undefined
		|| process.env.NEXT_PUBLIC_RELEASE_STAGE === 'local'
		|| process.env.NEXT_PUBLIC_HOSTNAME.includes('.test')
}

export function getSingleErrors(key, errors = errorList) {
	return errors[key]?.map((item, index) =>
		<div className="text-red-500 text-sm" key={index}>{item}</div>
	)
}

export function capitlizeFirst(str) {
	// checks for null, undefined and empty string
	if (!str) return;
	return str.match("^[a-z]") ? str.charAt(0).toUpperCase() + str.substring(1) : str;
}

export const TypingDuration = 500

export const getCookie = (name) => {
	// Split cookie string and get all individual name=value pairs in an array
	var cookieArr = document.cookie.split(";");
	// Loop through the array elements
	for(var i = 0; i < cookieArr.length; i++) {
		var cookiePair = cookieArr[i].split("=");
		/* Removing whitespace at the beginning of the cookie name
		and compare it with the given string */
		if(name == cookiePair[0].trim()) {
			// Decode the cookie value and return
			return decodeURIComponent(cookiePair[1]);
		}
	}
	// Return null if not found
	return null;
}

export const deleteCookie = (name) => {
	document.cookie = name +'=; Path=/;  Domain='+process.env.NEXT_PUBLIC_DOMAIN+'; Expires=Thu, 01 Jan 1970 00:00:01 GMT; SameSite=None; Secure'
}

export function deepmerge() {
	const merge = function () {
		let target = arguments[0];
		for (let i = 1; i < arguments.length; i++) {
			let arr = arguments[i];
			for (let k in arr) {
				if (Array.isArray(arr[k])) {
					if (target[k] === undefined) {
						target[k] = [];
					}
					target[k] = [...new Set(target[k].concat(...arr[k]))];
				} else if (typeof arr[k] === "object") {
					if (target[k] === undefined) {
						target[k] = {};
					}
					target[k] = merge(target[k], arr[k]);
				} else {
					target[k] = arr[k];
				}
			}
		}
		return target;
	};
	return merge(...arguments);
}

export function everythingAfter(separator, string) {
	const queryString = string;
	const index = queryString.indexOf(separator);
	if (index !== -1) {
		return '?' + queryString.substring(index + 1);
	} else {
		return '';
	}
}

export  function isEmpty(obj) {
	return Object.keys(obj).length === 0;
}
